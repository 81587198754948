import React from "react";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";
import { colors } from "@puzzle/theme";

export default function NavAccountant({
  viewBox = "0 0 24 24",
  active,
  color = active ? Colors.Active : Colors.BaseGrey,
  ...iconProps
}: IconProps) {
  return (
    <Icon size={24} {...iconProps} viewBox={viewBox}>
      <g fill={colors.neutral500} clipPath="url(#clip0_75_1741)">
        <path d="M13 15.25c2.143 0 3.125 0 3.75 1.895v1.23h-7.5v-1.23c.625-1.895 1.607-1.895 3.75-1.895zM15.188 11.813a2.188 2.188 0 11-4.376 0 2.188 2.188 0 014.376 0z"></path>
      </g>
      <path
        fill={color}
        d="M5.5 0C2.467 0 0 2.165 0 4.828c0 2.663 2.467 4.827 5.5 4.827.318 0 .635-.024.95-.072l2.498 1.361a.459.459 0 00.677-.402V7.985A4.483 4.483 0 0011 4.828C11 2.165 8.533 0 5.5 0z"
      ></path>
      <defs>
        <clipPath id="clip0_75_1741">
          <path fill={colors.white} d="M0 0H10V10H0z" transform="translate(8 9)"></path>
        </clipPath>
      </defs>
    </Icon>
  );
}
