import React from "react";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";
import { colors } from "@puzzle/theme";

export default function PeopleCard({
  active,
  size = 24,
  viewBox = "0 0 24 24",
  ...iconProps
}: IconProps) {
  return (
    <Icon size={size} {...iconProps} viewBox={viewBox}>
      <path
        d="M20.4375 4.125H3.5625C2.61328 4.125 1.875 4.89844 1.875 5.8125V18.1875C1.875 19.1367 2.61328 19.875 3.5625 19.875H20.4375C21.3516 19.875 22.125 19.1367 22.125 18.1875V5.8125C22.125 4.89844 21.3516 4.125 20.4375 4.125ZM8.0625 7.5C9.29297 7.5 10.3125 8.51953 10.3125 9.75C10.3125 11.0156 9.29297 12 8.0625 12C6.79688 12 5.8125 11.0156 5.8125 9.75C5.8125 8.51953 6.79688 7.53516 8.0625 7.5ZM12 15.832C12 16.2188 11.6484 16.5 11.1914 16.5H4.89844C4.47656 16.5 4.125 16.2188 4.125 15.832V15.1641C4.125 14.0391 5.17969 13.125 6.48047 13.125H6.65625C7.00781 13.3008 7.64062 13.4062 8.0625 13.4062C8.44922 13.4062 9.08203 13.3008 9.43359 13.125H9.60938C10.9102 13.125 12 14.0391 12 15.1641V15.832ZM19.875 13.9688C19.875 14.1445 19.7344 14.25 19.5938 14.25H14.5312C14.3555 14.25 14.25 14.1445 14.25 13.9688V13.4062C14.25 13.2656 14.3555 13.125 14.5312 13.125H19.5938C19.7344 13.125 19.875 13.2656 19.875 13.4062V13.9688ZM19.875 11.7188C19.875 11.8945 19.7344 12 19.5938 12H14.5312C14.3555 12 14.25 11.8945 14.25 11.7188V11.1562C14.25 11.0156 14.3555 10.875 14.5312 10.875H19.5938C19.7344 10.875 19.875 11.0156 19.875 11.1562V11.7188ZM19.875 9.46875C19.875 9.64453 19.7344 9.75 19.5938 9.75H14.5312C14.3555 9.75 14.25 9.64453 14.25 9.46875V8.90625C14.25 8.76562 14.3555 8.625 14.5312 8.625H19.5938C19.7344 8.625 19.875 8.76562 19.875 8.90625V9.46875Z"
        fill={active ? Colors.Active : Colors.BaseGrey}
      />
      <path
        d="M8.0625 12C9.29297 12 10.3125 11.0156 10.3125 9.75C10.3125 8.51953 9.29297 7.5 8.0625 7.5C6.79688 7.5 5.8125 8.51953 5.8125 9.75C5.8125 11.0156 6.79688 12 8.0625 12ZM9.60938 13.125H9.43359C9.08203 13.3008 8.44922 13.4062 8.0625 13.4062C7.64062 13.4062 7.00781 13.3008 6.65625 13.125H6.48047C5.17969 13.125 4.125 14.0391 4.125 15.1641V15.832C4.125 16.2188 4.47656 16.5 4.89844 16.5H11.1914C11.6484 16.5 12 16.2188 12 15.832V15.1641C12 14.0391 10.9102 13.125 9.60938 13.125Z"
        fill={colors.neutral800}
      />
    </Icon>
  );
}
