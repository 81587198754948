import React from "react";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";
import { colors } from "@puzzle/theme";

export default function Dashboard({ active, viewBox = "0 0 22 16", ...iconProps }: IconProps) {
  return (
    <Icon height={16} width={22} {...iconProps} viewBox={viewBox}>
      <path
        d="M11 0.125C5.375 0.125 0.875 4.66016 0.875 10.25V10.2852C0.875 11.7969 1.47266 14.0469 2.24609 15.3477C2.42188 15.6992 2.80859 15.875 3.19531 15.875H18.7695C19.1562 15.875 19.543 15.6992 19.7188 15.3477C20.4922 14.0469 21.125 11.7969 21.125 10.2852V10.25C21.125 4.66016 16.5898 0.125 11 0.125ZM16.7305 6.27734L12.8984 11.375C13.0742 11.6562 13.25 12.1836 13.25 12.5C13.25 12.8516 13.1094 13.3438 12.9336 13.625H9.03125C8.89062 13.3438 8.75 12.8516 8.75 12.5C8.75 11.2695 9.73438 10.25 11 10.25C11.1406 10.2852 11.3867 10.3203 11.5625 10.3555L15.3594 5.25781C15.5 5.08203 15.8164 4.90625 16.0625 4.90625C16.5195 4.90625 16.9062 5.29297 16.9062 5.75C16.9062 5.92578 16.8008 6.13672 16.7305 6.27734Z"
        fill={active ? Colors.Active : Colors.BaseGrey}
      />
      <path
        d="M11 10.25C9.73438 10.25 8.75 11.2695 8.75 12.5C8.75 12.8516 8.89062 13.3438 9.03125 13.625H12.9336C13.1094 13.3438 13.25 12.8516 13.25 12.5C13.25 12.1836 13.0742 11.6562 12.8984 11.375L16.7305 6.27734C16.8008 6.13672 16.9062 5.92578 16.9062 5.75C16.9062 5.29297 16.5195 4.90625 16.0625 4.90625C15.8164 4.90625 15.5 5.08203 15.3594 5.25781L11.5625 10.3555C11.3867 10.3203 11.1406 10.2852 11 10.25Z"
        fill={colors.neutral800}
      />
    </Icon>
  );
}
