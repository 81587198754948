import React from "react";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";
import { colors } from "@puzzle/theme";

export default function Folder({ active, viewBox = "0 0 25 24", ...iconProps }: IconProps) {
  return (
    <Icon width={25} height={24} {...iconProps} viewBox={viewBox}>
      <path
        d="M3.5625 20H16.5625C17.375 20 18.0625 19.3438 18.0625 18.5V17H7.5625C6.15625 17 5.0625 15.9062 5.0625 14.5V8H3.5625C2.71875 8 2.0625 8.6875 2.0625 9.5V18.5C2.0625 19.3438 2.71875 20 3.5625 20Z"
        fill={active ? Colors.ActiveGrey : colors.neutral500}
      />
      <path
        d="M22.0625 7.5C22.0625 6.6875 21.375 6 20.5625 6H14.5625L12.5625 4H7.5625C6.71875 4 6.0625 4.6875 6.0625 5.5V14.5C6.0625 15.3438 6.71875 16 7.5625 16H20.5625C21.375 16 22.0625 15.3438 22.0625 14.5V7.5Z"
        fill={active ? Colors.Active : Colors.BaseGrey}
      />
    </Icon>
  );
}
